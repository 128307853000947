import React, { Component } from "react"
import { graphql } from "gatsby"

import { Layout, Seo, Hero, Preview, Sidebar } from "../components"

class Homepage extends Component {
  render() {
    const data = this.props.data

    return (
      <Layout>
        <Seo title="Manchester Based New Media Film Studio" pathname={this.props.location.pathname} />
        <Hero title={data.featuredPost.edges[0].node.title} excerpt={data.featuredPost.edges[0].node.excerpt} button="Read More" link={data.featuredPost.edges[0].node.slug} image={data.featuredPost.edges[0].node.featured_media.localFile.childImageSharp.fluid} />
        <section className="section">
          <div className="container">
            <div className="columns ">
              <div className="column has-background-white is-two-thirds">
                <div className="columns is-multiline">
                  {data.allWordpressPost.edges.map(({ node }) => (
                    <Preview post={node} key={node.slug} />
                  ))}
                </div>
              </div>
              <div className="has-background-grey-lighter column">
                <Sidebar />
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default Homepage

export const pageQuery = graphql`
  query MyQuery {
    allWordpressPost(filter: {sticky: {eq: true}}) {
      edges {
        node {
          title
          excerpt
          slug
          date
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxHeight: 300, maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
    featuredPost: allWordpressPost(limit: 1, filter: {tags: {elemMatch: {slug: {eq: "featured"}}}}) {
      edges {
        node {
          title
          excerpt
          slug
          featured_media {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`